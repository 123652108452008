import {registerOptions, StepProps} from "../DevisFormData";
import {FC} from "react";

const SecondStep: FC<StepProps> = ({errors, register, watchField}) => {

    const isProfessionnel = watchField === "professionnel";

    return (
        <div className="input-text-container">
            {isProfessionnel && <div className="input-text-container">
                <label htmlFor="nomEntreprise">Nom de l'entreprise*</label>
                <input type="text" id="nomEntreprise"
                       {...register("nomEntreprise", registerOptions.nomEntreprise)}
                />
                {errors?.nomEntreprise && <p className="form-error">{errors.nomEntreprise.message}</p>}
            </div>
            }
            {isProfessionnel && <div className="input-text-container">
                <label htmlFor="activitePrincipale">Activité principale*</label>
                <input type="text" id="activitePrincipale"
                       {...register("activitePrincipale", registerOptions.activitePrincipale)}
                />
                {errors?.activitePrincipale && <p className="form-error">{errors.activitePrincipale.message}</p>}
            </div>
            }
            <div className="input-text-container">
                <label htmlFor="adresse">Adresse*</label>
                <input type="text" id="adresse"
                       {...register("adresse", registerOptions.adresse)}
                />
                {errors?.adresse && <p className="form-error">{errors.adresse.message}</p>}
            </div>
            <div className="input-text-container">
                <label htmlFor="codePostal">Code postal*</label>
                <input type="number" id="codePostal"
                       {...register("codePostal", registerOptions.codePostal)}
                />
                {errors?.codePostal && <p className="form-error">{errors.codePostal.message}</p>}
            </div>
            <div className="input-text-container">
                <label htmlFor="ville">Ville*</label>
                <input type="text" id="ville"
                       {...register("ville", registerOptions.ville)}
                />
                {errors?.ville && <p className="form-error">{errors.ville.message}</p>}
            </div>

        </div>
    );
}

export default SecondStep;