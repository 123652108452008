import React from 'react';
import './App.css';
import Footer from "./components/common/Footer";
import Navbar from "./components/common/Navbar";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./utils/Router";
import ScrollToTop from "./utils/ScrollToTop";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import Bugsnag from "@bugsnag/js";

const App = () => {

     Bugsnag.start({
        // @ts-ignore
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginReact()]
    })

    //@ts-ignore
    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

    return (
       <ErrorBoundary>
            <BrowserRouter>
                <ScrollToTop />
                <Navbar/>
                <AppRouter/>
                <Footer/>
            </BrowserRouter>
       </ErrorBoundary>

    );
}

export default App;
