import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Accueil from "../components/accueil/Accueil";
import APropos from "../components/aPropos/APropos";
import ContactDevis from "../components/contact-devis/ContactDevis";
import Services from "../components/services/Services";

const AppRouter = () => {

    return (
        <Routes>
            <Route path="/" element={<Accueil/>}/>
            <Route path="/a-propos" element={<APropos/>}/>
            <Route path="/contact" element={<ContactDevis/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
}

export default AppRouter;