import Button from "../common/Button";
import ContactInformations from "../common/ContactInformations";
import "./Services.css";

const Services = () => {
    return (
        <div className="services-container">
            <div className="background-image top-background-services">
                <img alt="logo à la belle devanture" src="/images/icons/logo_plein_blanc.png" className="logo"/>
            </div>
            <section className="professionnels-container">
                <h2 id="professionnels">Vous êtes un professionnel ?</h2>
                <p>
                    Pour les entreprises, nous proposons un service de lavage de vitres commercial, qui comprend le
                    <strong> nettoyage des vitres de bureaux, magasins et autres bâtiments professionnels. </strong>
                    Nous proposons également des options de nettoyage supplémentaires, telles que le dépoussiérage des
                    châssis et des volets, pour un nettoyage en profondeur de vos vitres.
                    Nous sommes équipés pour nettoyer des vitres de grandes tailles et en hauteur
                    <strong> jusqu’à 8m de hauteur</strong>, pour un résultat impeccable.</p>
                <Button text="Demander un devis" color="salmon-button" navigate="contact"/>
            </section>

            <section className="background-image bottom-background-services">
                <div>
                    <h2 id="particuliers">Vous êtes un particulier ?</h2>
                    <p>A la belle devanture, nous proposons différents types de lavage de vitres, adaptés à vos besoins.
                        Pour les particuliers, nous proposons un service de lavage de vitres résidentiel, qui comprend le
                        <strong> nettoyage des vitres, fenêtres, baies vitrées et vérandas </strong>
                        pour vous offrir une vue claire sur l’extérieur.</p>
                    <Button text="Demander un devis" color="white-button" navigate="contact"/>
                </div>
            </section>

            <ContactInformations/>
        </div>);
}

export default Services;