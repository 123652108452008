import DevisForm from "./DevisForm";
import React from "react";
import "./ContactDevis.css"

const ContactDevis = () => {

    const LILLE_PHONE_NUMBER = "06 04 11 76 56";
    const VANNES_PHONE_NUMBER = "07 44 93 39 05";

    return (
        <div className="contact-container">
            <div className="background-image background-contact">
                <img alt="logo à la belle devanture" src="/images/icons/logo_plein_blanc.png" className="logo"/>
            </div>

            <div id="contact"></div>
            <h2>Vous souhaitez faire appel à nos services ?</h2>

            <p><strong>C’est avec plaisir que nous répondrons à votre demande ! </strong>
                Nous nettoyons vos vitres au juste prix. Après une estimation du travail à effectuer par téléphone,
                nous vous communiquons un tarif pour le périmètre établi ensemble, à valider sur place avant
                l’intervention avec notre laveur de vitres. <strong>N’hésitez pas à nous contacter pour établir un
                    devis,
                </strong> par téléphone ou en remplissant le formulaire ci-dessous :</p>

            <div className="button-container">
                <div className="location-group">

                    <h2>Lille</h2>
                <button className="button blue-button">
                        <a href={`tel:${LILLE_PHONE_NUMBER}`}>{LILLE_PHONE_NUMBER}</a>
                    </button>
                </div>
                <div className="location-group">
                    <h2>Vannes</h2>
                    <button className="button blue-button">
                        <a href={`tel:${VANNES_PHONE_NUMBER}`}>{VANNES_PHONE_NUMBER}</a>
                    </button>
                </div>
            </div>

            <DevisForm/>

        </div>
    );
}

export default ContactDevis;