import {StepProps} from "../DevisFormData";
import {FC} from "react";

const ThirdStep: FC<StepProps> = ({register}) => {

    return (
        <div className="form-step third-step">
            <div className="input-text-container">
                <label className="form-description" htmlFor="demande">Votre demande*</label>
                <p className="form-description-detail">Une info utile à nous communiquer ?</p>
                <textarea id="demande"  {...register("demande")}/>
            </div>
            <fieldset className="custom-radio-input">
                <legend>Comment nous avez-vous connus ?</legend>
                <div>
                    <input type="radio" id="connaissance" value="connaissance"
                           {...register("moyenConnaissance")}
                    />
                    <label htmlFor="connaissance">Par des connaissances</label>
                </div>
                <div>
                    <input type="radio" id="internet" value="internet"
                           {...register("moyenConnaissance")}
                    />
                    <label htmlFor="internet">Via une recherche internet</label>
                </div>
                <div>
                    <input type="radio" id="reseaux" value="reseaux"
                           {...register("moyenConnaissance")}
                    />
                    <label htmlFor="reseaux">Via les réseaux sociaux</label>
                </div>
                <div>
                    <input type="radio" id="recommandation" value="recommandation"
                           {...register("moyenConnaissance")}
                    />
                    <label htmlFor="recommandation">Sur recommandation d'autres clients</label>
                </div>
                <div>
                    <input type="radio" id="vu-velo" value="vu-velo"
                           {...register("moyenConnaissance")}
                    />
                    <label htmlFor="vu-velo">Je vous ai vu à vélo</label>
                </div>
                <div>
                    <input type="radio" id="autre" value="autre"
                           {...register("moyenConnaissance")}
                    />
                    <label htmlFor="autre">Autre :</label>
                    <input type="text" id="other-choice"
                        {...register("autreRaison")}/>
                </div>
            </fieldset>
        </div>
    );
}

export default ThirdStep;