import React, {Dispatch, FC, SetStateAction} from "react";
import "./FormModal.css";
import {UseFormReset} from "react-hook-form";
import {DevisFormData} from "./DevisFormData";
import {useNavigate} from "react-router-dom";

interface FormModalProps {
    text: string,
    isVisible: boolean,
    setIsVisible: Dispatch<SetStateAction<boolean>>,
    setPage: Dispatch<SetStateAction<number>>,
    reset: UseFormReset<DevisFormData>
}

const FormModal: FC<FormModalProps> = ({text, isVisible, setIsVisible, setPage, reset}) => {

    const navigate = useNavigate();

    /* click outside of the window closes the modal */
    window.onclick = function (event) {
        const modal = document.getElementById("form-modal");
        if (event.target === modal) {
            close();
        }
    }

    const close = () => {
        setIsVisible(false);
        reset();
        setPage(1);
        navigate("/");
    }

    return (
        <div id="form-modal" className={`modal ${isVisible? "visible" : "invisible"}`}>
            <div className="modal-content">
                <p>{text}</p>
                <button className="button blue-button close" onClick={close}>Fermer</button>
            </div>
        </div>
    );
}

export default FormModal;