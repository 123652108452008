import {registerOptions, StepProps} from "../DevisFormData";
import {FC} from "react";

const FirstStep: FC<StepProps> = ({errors, register}) => {

    return (
        <div className="form-step first-step">
            <p className="form-description">Remplissez ce formulaire de demande de devis :</p>
            <div className="input-text-container">
                <label htmlFor="nom">Nom*</label>
                <input type="text" id="nom"
                       {...register("nom", registerOptions.nom)}
                />
                {errors?.nom && <p className="form-error">{errors.nom.message}</p>}
            </div>
            <div className="input-text-container">
                <label htmlFor="prenom">Prénom*</label>
                <input type="text" id="prenom"
                       {...register("prenom", registerOptions.prenom)}
                />
                {errors?.prenom &&  <p className="form-error">{errors.prenom.message}</p>}
            </div>
            <div className="input-text-container">
                <label htmlFor="telephone">Téléphone*</label>
                <input type="tel" id="telephone"
                       {...register("telephone", registerOptions.telephone)}
                />
                {errors?.telephone &&  <p className="form-error">{errors.telephone.message}</p>}
            </div>
            <div className="input-text-container">
                <label htmlFor="email">Email*</label>
                <input type="email" id="email"
                       {...register("email", registerOptions.email)}
                />
                {errors?.email &&  <p className="form-error">{errors.email.message}</p>}
            </div>
            <fieldset className="custom-radio-input">
                <legend>Vous êtes*</legend>
                <div>
                    <input type="radio" id="professionnel" value="professionnel"
                           {...register("typePersonne", {required: true})}
                    />
                    <label htmlFor="professionnel">Un professionnel</label>
                </div>
                <div>
                    <input type="radio" id="particulier" value="particulier"
                           {...register("typePersonne", {required: true})}
                    />
                    <label htmlFor="particulier">Un particulier</label>
                </div>
            </fieldset>
        </div>
    );
}

export default FirstStep;