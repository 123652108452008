import React, {useState} from "react";
import SecondStep from "./steps/SecondStep";
import FirstStep from "./steps/FirstStep";
import ThirdStep from "./steps/ThirdStep";
import {DevisFormData} from "./DevisFormData";
import {useForm} from "react-hook-form";
import "./DevisForm.css";
import FormModal from "./FormModal";
import Bugsnag from "@bugsnag/js";

const {GoogleSpreadsheet} = require('google-spreadsheet');

const DevisForm = () => {

    const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SPREADSHEET_ID);

    const {register, watch, handleSubmit, reset, trigger, formState: {errors}} = useForm<DevisFormData>(
        {
            mode: "all",
            defaultValues: {
                typePersonne: "professionnel",
                moyenConnaissance: "connaissance"
            }
        }
    );

    const watchTypePersonne = watch("typePersonne");
    const [page, setPage] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalText, setModalText] = useState("");

    const getStepToDisplay = () => {
        switch (page) {
            case 1:
                return <FirstStep register={register} errors={errors}/>;
            case 2:
                return <SecondStep register={register} errors={errors} watchField={watchTypePersonne}/>;
            case 3:
                return <ThirdStep register={register} errors={errors}/>;
            default:
                return <FirstStep register={register} errors={errors}/>;
        }
    }

    const onValid = (data: any) => {
        fillGoogleSheet(data)
            .then(() => {
                setModalText("Demande de devis envoyée avec succès ! Vous serez recontacté sous peu.");
                setIsModalVisible(true);
                reset();
            })
            .catch((error) => {
                console.log('Erreur :', error);
                Bugsnag.notify(new Error("Erreur lors de l'envoi du devis : " + error));
                setModalText("Erreur de connexion au serveur, veuillez réessayer svp.\n " +
                    "Vous pouvez également nous appeler ou nous envoyer un email directement !");
                setIsModalVisible(true);
            });
    }

    const getFormattedFormToSend = (form: DevisFormData) => {
        return {
            "Date envoi": new Date().toLocaleString("fr-FR"),
            "Nom": form.nom,
            "Prénom": form.prenom,
            "Téléphone": form.telephone,
            "Email": form.email,
            "Type personne": form.typePersonne,
            "Nom entreprise": form.nomEntreprise,
            "Activité principale": form.activitePrincipale,
            "Adresse": form.adresse,
            "Code postal": form.codePostal,
            "Ville": form.ville,
            "Moyen connaissance": form.moyenConnaissance !== "autre" ? form.moyenConnaissance : "Autre : " + form.autreRaison,
            "Demande": form.demande
        }
    }

    const fillGoogleSheet = async (form: DevisFormData) => {
        await doc.useServiceAccountAuth({
            client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_MAIL,
            private_key: process.env.REACT_APP_GOOGLE_SHEET_API_KEY,
        });
        await doc.loadInfo();
        const sheet = doc.sheetsById[process.env.REACT_APP_GOOGLE_SHEET_ID as string];
        await sheet.addRow(getFormattedFormToSend(form));
    }

    const isFirstStepValid = async (): Promise<boolean> => {
        return await trigger(['nom', 'prenom', 'telephone', 'email']);
    }

    const isSecondStepValid = async (): Promise<boolean> => {
        return await trigger(['nomEntreprise', 'activitePrincipale', 'adresse', 'codePostal', 'ville']);
    }

    const goToNextStep = async () => {
        if (page === 1 && await isFirstStepValid()) {
            setPage(2);
        } else if (page === 2 && await isSecondStepValid()) {
            setPage(3);
        }
    }

    const goToPrevStep = () => {
        setPage(prevPage => prevPage - 1);
    }

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit(onValid)} className="devis-form">
                {getStepToDisplay()}
                <div className="form-buttons">
                    <div className="navigation-buttons">
                        {page !== 1 && <button className="button blue-button prev-button" onClick={goToPrevStep}>Retour</button>}
                        {page !== 3 && <button className="button blue-button next-button" onClick={goToNextStep}>Suivant</button>}
                        {page === 3 && <button className="button blue-button next-button" type="submit">Envoyer</button>}
                    </div>
                    <div className="page-counter">
                        <div className="dot-navigation">
                            <div className={`dot-navigation-item ${page === 1 ? 'dot-active' : ''}`}></div>
                            <div className={`dot-navigation-item ${page === 2 ? 'dot-active' : ''}`}></div>
                            <div className={`dot-navigation-item ${page === 3 ? 'dot-active' : ''}`}></div>
                        </div>
                    </div>
                </div>
            </form>
            <FormModal isVisible={isModalVisible} setIsVisible={setIsModalVisible} text={modalText} reset={reset}
                       setPage={setPage}/>
        </div>
    );
}

export default DevisForm;