import {FieldErrors, UseFormRegister} from "react-hook-form";

const NAME_PATTERN = /^[\D]+$/;
const MAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PHONE_PATTERN = /^\+?(\s|\d){10,20}$/;
const ZIP_CODE_PATTERN = /^(\d){5}$/;

export interface DevisFormData {
    nom: string,
    prenom: string,
    telephone: string,
    email: string,
    typePersonne: string,
    nomEntreprise: string,
    activitePrincipale: string,
    adresse: string,
    codePostal: string,
    ville: string,
    demande: string,
    moyenConnaissance: string,
    autreRaison: string
}

export interface StepProps {
    register: UseFormRegister<DevisFormData>,
    errors:  FieldErrors<DevisFormData>,
    watchField?: string
}

export const registerOptions = {
    nom: {
        required: "Nom manquant",
        pattern: {
            value: NAME_PATTERN,
            message: "Nom invalide"
        }
    },
    prenom: {
        required: "Prénom manquant",
        pattern: {
            value: NAME_PATTERN,
            message: "Prénom invalide"
        }
    },
    email: {
        required: "Email manquant",
        pattern: {
            value: MAIL_PATTERN,
            message: "Email invalide"
        }
    },
    telephone: {
        required: "Téléphone manquant",
        pattern: {
            value: PHONE_PATTERN,
            message: "Téléphone invalide"
        }
    },
    nomEntreprise: {
        required: "Nom de l'entreprise manquant",
    },
    activitePrincipale: {
        required: "Activité principale manquante",
    },
    adresse: {
        required: "Adresse manquante",
    },
    codePostal: {
        required: "Code postal manquant",
        pattern: {
            value: ZIP_CODE_PATTERN,
            message: "Code postal invalide"
        }
    },
    ville: {
        required: "Ville manquante",
    }
};