import "./APropos.css";
import React from "react";

const APropos = () => {

    const MAIL_TO_SEND = "mailto:alabelledevanture@gmail.com?subject=Candidature spontanée";

        return (
            <div className="a-propos-container">
                <div className="background-image background-a-propos">
                    <img alt="logo à la belle devanture" src="/images/icons/logo_plein_blanc.png" className="logo"/>
                </div>
                <h2 id="lille">Notre équipe à Lille</h2>
                <article className="member-container elisabeth">
                    <div className="image-container">
                        <img alt="Elisabeth" src="/images/photos/portrait_elisabeth.webp"
                             className="portrait photo-elisabeth"/>
                        <img alt="motif" src="/images/icons/motif-2.png" className="motif motif-top"/>
                        <img alt="dessin d'un devanture de maison" src="/images/icons/devanture_3.png"
                             className="devanture devanture-top"/>
                    </div>
                    <div className="description-elisabeth">
                        <h3>Elisabeth</h3>
                        <p>J’ai créé <strong>à la belle devanture</strong> en janvier 2023.
                            J’ai repris la suite de la vitrocyclette car je suis convaincue de l’intérêt et de la beauté
                            de
                            ce
                            projet plein de sens, <strong>tourné vers les autres et le service.</strong>
                        </p>
                        <p>Après plusieurs années dans le domaine de la vente, j’ai saisi cette opportunité de
                            reconversion
                            professionnelle pour être à mon compte.
                            Ce qui m’a séduite avec cette activité c’est <strong>l’autonomie</strong> qu’elle m’apporte,
                            <strong> le contact avec les clients</strong> et<strong> le travail en plein air !</strong>
                        </p>
                        <p>Je suis déterminée à fournir un service de qualité et respectueux de l’environnement !
                            Je vous dis donc à très bientôt, au détour d’une rue de notre belle ville !
                        </p>
                    </div>
                </article>

                <article className="member-container pierre">
                    <div className="description-pierre">
                        <h3>Pierre</h3>
                        <p>
                            Originaire d’Auvergne, j’ai d’abord été croupier dans différents casinos en Suisse 🇨🇭
                            puis en France, ce qui m’a amené à Lille.
                            J’ai ensuite changé de voie pour devenir crémier dans des fromageries lilloises,
                            où j’ai rencontré Sylvain et plus tard Elisabeth.<br></br>
                            C’est ainsi que j’ai eu envie de vivre une <strong>nouvelle aventure
                            professionnelle </strong>
                            en rejoignant l’équipe d’à la belle devanture ! <br></br>
                            Les déplacements à vélo, l’autonomie de travail,
                            l’échange avec nos différents clients, <strong>les valeurs écologiques de
                            l’entreprise </strong>
                            sont toutes les raisons pour lesquelles j’ai voulu rejoindre le projet.
                            Consciencieux dans mon travail, j’aime quand le client est satisfait.
                        </p>
                    </div>
                    <div className="image-container">
                        <img alt="motif" src="/images/icons/motif-1.png" className="motif motif-bottom"/>
                        <img alt="dessin d'un devanture de maison" src="/images/icons/devanture_4.png"
                             className="devanture devanture-bottom"/>
                        <img alt="Pierre" src="/images/photos/pierre.webp" className="portrait photo-pierre"/>
                    </div>
                </article>

                <h2 id="vannes">Notre équipe à Vannes</h2>
                <article className="member-container sylvain" id="sylvain">
                    <div className="description-sylvain">
                        <h3>Sylvain</h3>
                        <p> Après un parcours enrichissant en tant que cuisinier traiteur, j'ai rebondi sur un nouveau
                            défi professionnel en rejoignant l'équipe d'À la belle devanture à Lille .
                            Travailler en autonomie, se déplacer en vélo, échanger avec des personnes et s'enrichir
                            chaque jour de nouvelles rencontres, tout en faisant partie d'un <strong>projet respectueux
                                de l'environnement</strong> sont autant de petites choses qui donnent du sens à ma
                            nouvelle vie professionnelle.
                            Tout comme en cuisine, j'aime le <strong>travail bien fait</strong>, dans la bonne humeur et
                            j'aime voir la satisfaction des clients suite à mon passage chez eux.
                            Toujours à la recherche de nouveaux défis, j'ai choisi le Morbihan pour créer la <strong>première
                                franchise</strong> d'À la belle devanture.
                            Au plaisir de vous croiser dans les rues de Vannes et d'Auray ! 🌞
                        </p>
                    </div>
                    <div className="image-container">
                        <img alt="motif" src="/images/icons/motif-1.png" className="motif motif-bottom"/>
                        <img alt="dessin d'un devanture de maison" src="/images/icons/devanture_4.png"
                             className="devanture devanture-bottom"/>
                        <img alt="Sylvain" src="/images/photos/sylvain.webp" className="portrait photo-sylvain"/>
                    </div>
                </article>


                <article className="nous-rejoindre-container">
                    <h2 id="nous-rejoindre">Envie de nous rejoindre ?</h2>
                    <p>Si vous aimez apporter la lumière à vos voisins et mettre en valeur les vitrines de vos
                        commerçants,
                        alors le métier de laveur de vitres à vélo est peut-être fait pour vous !
                        <strong> Envoyez nous vos motivations pour nous rejoindre</strong> et découvrir la joie dans le
                        sourire de vos clients devant leurs vitres éclatantes à chacune de vos interventions.
                    </p>
                    <button className={`button light-salmon-button`}><a href={MAIL_TO_SEND}>Postuler</a></button>
                </article>
            </div>
        );

}

export default APropos;