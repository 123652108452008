import "./ContactInformations.css";
import React from "react";

const ContactInformations = () => {

    return (
        <article className="contact-informations">
            <h2>Un devis ? Une question ?</h2>
            <p>Envie de vitres vraiment propres ? Rejoignez l’aventure !</p>
            <p><strong>Contactez-nous pour votre devis sur-mesure !</strong></p>

            <section className="contact-buttons">
                <button className="button phone-button salmon-button"><a href={`/contact/#contact`}>{"Nous contacter"}</a></button>
            </section>
        </article>
    );
}

export default ContactInformations;