import React, {useState} from "react";
// @ts-ignore
import {HashLink as Link} from 'react-router-hash-link';
import {slide as Menu, State} from 'react-burger-menu';
import "./BurgerMenu.css";

const BurgerMenu = () => {

    const [isOpen, setIsOpen] = useState(false);

    const closeMenu= () => {
        setIsOpen(false);
    }

    const handleStateChange = (state: State) => {
        setIsOpen(state.isOpen);
    }

    return (
        <Menu isOpen={isOpen} onStateChange={(state) => handleStateChange(state)}>
            <div className="page-wrap">
                <img alt="logo à la belle devanture" src="/images/icons/logo_plein_bleu.png" className="bm-logo"/>
                <Link to={"/"} onClick={closeMenu}>ACCUEIL</Link>
                <div className="separator"></div>
                <Link to={"/services"} onClick={closeMenu}>SERVICES</Link>
                <div className="separator"></div>
                <Link to={"/a-propos"} onClick={closeMenu}>À PROPOS</Link>
                <div className="separator"></div>
                <Link to={"/contact"} onClick={closeMenu}>CONTACT - DEVIS</Link>
            </div>
        </Menu>
    );
}

export default BurgerMenu;