import React, {Component} from "react";
import {config} from "react-spring";
import Carousel from "react-spring-3d-carousel";
import "./CustomCarousel.css";

const getTouches = (evt: any) => {
    return (
        evt.touches || evt.originalEvent.touches // browser API
    );
};

export default class CustomCarousel extends Component<any, any> {

    state = {
        goToSlide: 0,
        offsetRadius: 3,
        showNavigation: true,
        enableSwipe: true,
        config: config.slow,
        xDown: 2,
        yDown: 2
    };

    slides = [
        {
            key: 1,
            content: <img src="/images/photos/client_1.webp" alt="client Pokawa"/>,
        },
        {
            key: 2,
            content: <img src="/images/photos/client_4.webp" alt="client Papa Posto"/>
        },
        {
            key: 3,
            content: <img src="/images/photos/client_2.webp" alt="client le Lautrec"/>
        },
        {
            key: 4,
            content: <img src="/images/photos/client_5.webp" alt="client Papa Raffaele"/>
        },
        {
            key: 6,
            content: <img src="/images/photos/client_6.webp" alt="client Ellen Desforges"/>
        },
        {
            key: 7,
            content: <img src="/images/photos/client_7.webp" alt="client Century 21"/>
        },
        {
            key: 8,
            content: <img src="/images/photos/client_8.webp" alt="client Etre concept store"/>
        },
        {
            key: 9,
            content: <img src="/images/photos/client_9.webp" alt="client Mademoiselle Biloba"/>
        },
        {
            key: 10,
            content: <img src="/images/photos/client_10.webp" alt="client La pépite"/>
        },
        {
            key: 11,
            content: <img src="/images/photos/client_11.webp" alt="client La griffe"/>
        },
        {
            key: 12,
            content: <img src="/images/photos/client_12.webp" alt="client Lokarria"/>
        }







    ];

    handleTouchStart = (evt: any) => {
        if (!this.state.enableSwipe) {
            return;
        }
        const firstTouch = getTouches(evt)[0];
        this.setState({
            ...this.state,
            xDown: firstTouch.clientX,
            yDown: firstTouch.clientY
        });
    };

    handleTouchMove = (evt: React.TouchEvent<HTMLDivElement>) => {
        if (!this.state.enableSwipe || (!this.state.xDown && !this.state.yDown)) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = this.state.xDown - xUp;
        let yDiff = this.state.yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                /* left swipe */
                this.setState({
                    goToSlide: this.state.goToSlide + 1,
                    xDown: null,
                    yDown: null
                });
            } else {
                /* right swipe */
                this.setState({
                    goToSlide: this.state.goToSlide - 1,
                    xDown: null,
                    yDown: null
                });
            }
        }
    };

    render() {
        return (
            <div className="custom-carousel" onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove}>
                <Carousel
                    slides={this.slides}
                    goToSlide={this.state.goToSlide}
                    offsetRadius={this.state.offsetRadius}
                    showNavigation={this.state.showNavigation}
                    animationConfig={this.state.config}
                />
            </div>
        );
    }
}